<!-- 模板 -->
<template>
	<div class="">
		<div class="contant">
			<headers class="top" :active="4"></headers>
			<div style="overflow: unset">
				<img class="imhg" :src="img_alls" alt="" />
				<div class="boxal">
					<div class="boxal_a wu">
						<div class="zu">
							<div class="line"></div>
							<div class="tit">行业救助</div>
							<div class="line"></div>
						</div>
						<div class="chi">INDUSTRY ASSISTANCE</div>
						<div class="animal">
							<img :src="$baseUrl + first_img" alt="" class="animal_l" />
							<div class="animal_r">
								<div class="animal_rs" v-for="(it, i) in three_list" :key="i" @click="btn(it.id)">
									<div class="rs_l">
										<div class="mun">0{{ i + 1 }}</div>
									</div>

									<div class="rs_r">
										<div class="tits">{{ it.title }}</div>
										<div class="titm" v-html="it.content"></div>
									</div>
								</div>
							</div>
						</div>
						<!-- 事件 -->
						<div class="event">
							<div v-for="(it, i) in array" :key="i" class="events">
								<img :src="$baseUrl + it.image" alt="" class="images" />
								<div class="jiu">
									<div class="jiu_tit">{{ it.title }}</div>
									<div class="jiu_tits" v-html="it.content"></div>
									<div class="jiu_look" @click="btn(it.id)">立即查看</div>
								</div>
							</div>
						</div>
						<div class="fenye">
							<el-pagination background layout="prev, pager, next" :total="total" :page-size="7"
								@current-change="currentall">
							</el-pagination>
						</div>
					</div>
					<div class="boxal_b wu">
						<div class="zu">
							<div class="line"></div>
							<div class="tit">爱心捐助</div>
							<div class="line"></div>
						</div>
						<div class="chi">CARING DONATION</div>
						<div class="bs_one">
							<div v-for="(it, i) in love_list" class="one_detail" :key="i" @click="btn(it.id)">
								<img :src="$baseUrl + it.image" alt="" class="ban" />
								<div class="tit1">{{ it.title }}</div>
								<div class="tit2" v-html="it.content">
									<!-- 中国足道工作人员与繁华大道299号门口
                  的小树林救助小动物书名，目前小动物 已全部获救 -->
								</div>
								<div class="tit3">
									<div class="num">{{ it.donation_num }}</div>
									<div class="tit">人次已捐</div>
									<div class="numes">{{ it.donation_money }}</div>
									<div class="tit">元</div>
								</div>
								<el-progress :text-inside="true" :stroke-width="20" :percentage="it.donation_percentage"
									status="exception"></el-progress>
							</div>
						</div>
						<div class="event">
							<div v-for="(it, i) in lovearray" :key="i" class="events">
								<img :src="$baseUrl + it.image" alt="" class="images" />
								<div class="jiu">
									<div class="jiu_tit">{{ it.title }}</div>
									<div class="jiu_tits" v-html="it.content"></div>
									<div class="jiu_look" @click="btn(it.id)">爱心捐助</div>
								</div>
							</div>
						</div>
						<div class="fenye">
							<el-pagination background layout="prev, pager, next" :total="lovetotal" :page-size="8"
								@current-change="currenlove">
							</el-pagination>
						</div>
					</div>
				</div>
			</div>

			<footers></footers>
			<!-- <sides></sides> -->
		</div>
	</div>
</template>

<script>
	import request from "../utils/request.js";
	import headers from "../assembly/head.vue"; //头部
	import footers from "@/assembly/footer"; //页脚
	// import sides from "@/assembly/side"; //侧边
	export default {
		name: "",
		data() {
			return {
				img_alls: "",
				three_list: [],
				first_img: "",
				array: [],
				l_page: 1,
				total: null,
				lovetotal: null,
				lovearray: [],
				love_page: 1,
			};
		},
		props: [],
		components: {
			headers,
			footers,
			// sides,
		},
		computed: {},
		filters: {},
		created() {
			this.w_list();
			this.a_list();
			this.love_list();
		},
		methods: {
			currentall(e) {
				this.l_page = e;
				// console.log("点击", e);
				this.a_list();
			},
			currenlove(e) {
				this.love_page = e;
			},
			//爱心事件
			async love_list() {
				let options = {
					url: "/index/getContentByChannel",
					type: "post",
					data: {
						channel_id: 44,
						page: this.love_page,
						page_size: 8,
					},
				};
				try {
					let res = await request(options);
					if (res.data.code !== 1) {
						return this.$message.error("请求数据失败");
					}
					console.log(res.data.data);
					this.lovetotal = res.data.data.total;

					//前4个
					this.love_list = res.data.data.content.slice(0, 4);
					// this.love_img = this.three_list[0].image;
					// 下面数据
					this.lovearray = res.data.data.content.slice(4, 8);
					console.log(this.array);
				} catch (error) {
					console.log(error);
					this.$message.error("请求数据失败");
				}
			},
			//事件
			async a_list() {
				let options = {
					url: "/index/getContentByChannel",
					type: "post",
					data: {
						channel_id: 14,
						page: this.l_page,
						page_size: 7,
					},
				};
				try {
					let res = await request(options);
					if (res.data.code !== 1) {
						return this.$message.error("请求数据失败");
					}
					this.total = res.data.data.total;

					//前3个
					this.three_list = res.data.data.content.slice(0, 3);
					this.first_img = this.three_list[0].image;
					// 下面数据
					this.array = res.data.data.content.slice(3, 7);
				} catch (error) {
					console.log(error);
					this.$message.error("请求数据失败");
				}
			},
			//轮播图
			async w_list() {
				let options = {
					url: "/index/getContentByChannel",
					type: "post",
					data: {
						channel_id: 27,
					},
				};
				try {
					let res = await request(options);
					if (res.data.code !== 1) {
						return this.$message.error("请求数据失败");
					}
					//顶部图片
					this.img_alls = this.$baseUrl + res.data.data.content[0].images[0];
				} catch (error) {
					console.log(error);
					this.$message.error("请求数据失败");
				}
			},
			btn(id) {
				this.$router.push({
					path: "details",
					query: {
						id: id
					}
				});
			},
		},

		mounted() {
			let _this = this;
			// 页面渲染结束再执行动画
			this.$nextTick(function() {
				new _this.WOW({
					live: false
				}).init();
			});
		},
	};
</script>

<style lang="less" scoped>
	.fenye {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 20px;
	}

	//@import url(); 引入公共css类
	.contant {

		// position: relative;
		.top {
			width: 100%;

			top: 0;
			z-index: 100;
			position: sticky;
			top: 0;
			overflow-y: overlay;
		}

		.imhg {
			width: 100%;
			display: block;
		}

		.boxal {
			min-height: 300px;
			background-image: url("../assets/image/8.png");
			background-size: 100% 100%;
			background-repeat: no-repeat;
			padding: 60px 0;

			.boxal_a {
				.zu {
					display: flex;
					align-items: center;
					justify-content: center;

					.line {
						width: 120px;
						height: 2px;
						background: #776360;
						opacity: 1;
					}

					.tit {
						font-size: 40px;
						font-weight: bold;
						line-height: 68px;
						color: #776360;
						letter-spacing: 50px;
						opacity: 1;
						margin: 0 30px;
						padding-left: 40px;
					}
				}

				.chi {
					text-align: center;
					font-size: 16px;
					font-weight: 400;
					line-height: 27px;
					color: #776360;
				}

				.animal {
					display: flex;
					justify-content: space-between;
					margin-top: 60px;

					.animal_l {
						width: 590px;
						height: 300px;
					}

					.animal_r {
						width: 590px;
						background: #ffffff;
						border-radius: 10px;

						// height: 300px;
						// padding: 11px 0;
						.animal_rs {
							cursor: pointer;
							//
							display: flex;

							&:hover {
								background: #776360;
								border-radius: 10px;
							}

							&:hover .mun {
								color: #fff !important;
							}

							&:hover .tits {
								color: #fff !important;
							}

							&:hover .titm {
								color: #fff !important;
							}

							.rs_l {
								padding: 16px 0;

								.mun {
									// margin: 9px 0;
									padding: 0px 30px;
									font-size: 40px;
									font-weight: bold;
									line-height: 68px;
									color: #252121;
									opacity: 1;
									border-right: 1px solid #b2b1b1;
									margin-right: 30px;
								}
							}

							.rs_r {
								.tits {
									font-size: 20px;
									font-weight: bold;
									line-height: 34px;
									color: #252121;
									opacity: 1;
								}

								.titm {
									font-size: 14px;
									font-weight: 400;
									line-height: 21px;
									color: #252121;
									opacity: 1;
									padding-right: 21px;
								}
							}
						}
					}
				}

				.event {
					display: flex;
					flex-wrap: wrap;

					.events {
						cursor: pointer;
						width: 570px;
						display: flex;
						margin-top: 20px;
						padding: 10px;
						background-color: #fff;

						&:hover {
							background: #776360;
							color: #fff !important;
							border-radius: 10px;
						}
						&:hover .jiu>.jiu_look{
							background-color: #fff !important ;
							color: #776360;
						}

						&:hover .jiu_tit {
							color: #fff !important;
						}
						&:hover .jiu_tits {
							color: #fff !important;
						}
						

						.images {
							width: 200px;
							margin-right: 20px;
						}

						.jiu {
							.jiu_tit {
								font-size: 24px;
								font-weight: bold;
								line-height: 41px;
								color: #252121;
							}

							.jiu_tits {
								font-size: 14px;
								font-weight: 400;
								line-height: 21px;
								color: #252121;
								width: 224px;
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 3; //设置多少行
							}

							.jiu_look {
								margin-top: 20px;
								font-size: 16px;
								font-weight: 400;
								color: #ffffff;
								opacity: 1;
								display: inline-block;
								background: #776360;
								padding: 5px 10px;
								border-radius: 20px;
								cursor: pointer;
							}
						}

						&:nth-child(2n) {
							margin-left: 20px;
						}
					}
				}
			}

			.boxal_b {
				padding-top: 60px;

				.zu {
					display: flex;
					align-items: center;
					justify-content: center;

					.line {
						width: 120px;
						height: 2px;
						background: #776360;
						opacity: 1;
					}

					.tit {
						font-size: 40px;
						font-weight: bold;
						line-height: 68px;
						color: #776360;
						letter-spacing: 50px;
						opacity: 1;
						margin: 0 30px;
						padding-left: 40px;
					}
				}

				.chi {
					text-align: center;
					font-size: 16px;
					font-weight: 400;
					line-height: 27px;
					color: #776360;
				}

				.bs_one {
					display: flex;
					margin-top: 60px;

					.one_detail {
						cursor: pointer;
						flex: 1;
						margin-right: 20px;
						background-color: #fff;
						padding: 10px;

						&:last-child {
							margin-right: 0 !important;
						}

						.ban {
							width: 265px;
              height: 150px;
						}

						.tit1 {
							font-size: 24px;
							font-weight: bold;
							line-height: 41px;
							color: #252121;
						}

						.tit2 {
							font-size: 14px;
							font-weight: 400;
							line-height: 21px;
							color: #252121;
							opacity: 1;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 3; //设置多少行
							width: 90%;
						}

						.tit3 {
							display: flex;
							margin: 20px 0 10px;

							.num {
								font-size: 16px;
								font-weight: bold;
								line-height: 21px;
								color: #252121;
								opacity: 1;
							}

							.tit {
								font-size: 16px;
								font-weight: 400;
								line-height: 21px;
								color: #252121;
								opacity: 0.6;
							}

							.numes {
								font-size: 16px;
								font-weight: bold;
								line-height: 21px;
								color: #ee5b2d;
								opacity: 1;
							}
						}
					}
				}

				.event {
					display: flex;
					flex-wrap: wrap;

					.events {
						width: 570px;
						display: flex;
						margin-top: 20px;
						padding: 10px;
						background-color: #fff;

						.images {
							width: 200px;
							margin-right: 20px;
						}

						.jiu {
							.jiu_tit {
								font-size: 24px;
								font-weight: bold;
								line-height: 41px;
								color: #252121;
							}

							.jiu_tits {
								font-size: 14px;
								font-weight: 400;
								line-height: 21px;
								color: #252121;
								width: 224px;
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 3; //设置多少行
							}

							.jiu_look {
								margin-top: 20px;
								font-size: 18px;
								font-weight: 400;
								line-height: 24px;
								color: #ffffff;
								opacity: 1;
								display: inline-block;
								background: #ee5b2d;
								padding: 5px 10px;
								border-radius: 20px;
								cursor: pointer;
							}
						}

						&:nth-child(2n) {
							margin-left: 20px;
						}
					}
				}
			}
		}
	}
</style>
<style>
	::-webkit-scrollbar {
		width: 0;
		height: 0;
	}
</style>
